import React from 'react'
import { motion } from 'framer-motion'


const draw = {
    default:{
        pathLength: 0,
        pathLength: 1 ,
        transition:{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 2
        }
    }
};

const LoadingLogo = ({className}) => {
  return (
    <div className={className}>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 816 406.261"
        xmlSpace="preserve"
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.05, 1] }}
      >
        <defs>
          <clipPath id="clipPath22" clipPathUnits="userSpaceOnUse">
            <motion.path d="M0 304.696h612V0H0z"></motion.path>
          </clipPath>
        </defs>
        <g transform="matrix(1.33333 0 0 -1.33333 0 406.261)">
          <g transform="translate(74.11 50.03)">
            <motion.path
              fill="#2b2b2a"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0h8.275v-1.414H1.443v-4.525h6.773v-1.414H1.443v-5.673h6.773v-1.414H0z"
            ></motion.path>
          </g>
          <g>
            <g clipPath="url(#clipPath22)">
              {/* Start - Estudio Creativo - Subtitle */}
              <g transform="translate(102.285 38.28)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0l1.227.736c.864-1.59 1.862-2.384 2.994-2.384.484 0 .939.112 1.364.337.426.227.75.529.972.909.223.38.334.782.334 1.208 0 .483-.163.958-.491 1.423-.451.641-1.276 1.414-2.473 2.316-1.205.91-1.954 1.567-2.248 1.974-.511.68-.766 1.417-.766 2.209 0 .627.15 1.2.451 1.717.302.517.725.924 1.272 1.222.546.298 1.14.447 1.781.447.681 0 1.317-.169 1.91-.506.592-.337 1.218-.957 1.88-1.86l-1.179-.894c-.543.72-1.006 1.195-1.388 1.424a2.398 2.398 0 01-1.252.344c-.583 0-1.059-.177-1.429-.53a1.739 1.739 0 01-.554-1.306c0-.314.065-.618.196-.913.131-.294.37-.615.717-.962.19-.183.811-.664 1.865-1.443 1.25-.923 2.107-1.744 2.572-2.464.465-.72.697-1.442.697-2.169 0-1.048-.398-1.957-1.193-2.73-.795-.772-1.762-1.158-2.901-1.158a4.26 4.26 0 00-2.385.702C1.26-1.883.602-1.1 0 0"
                ></motion.path>
              </g>
              <g transform="translate(131.254 48.616)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0v1.414h7.912V0h-3.22v-13.026H3.22V0z"
                ></motion.path>
              </g>
              <g transform="translate(160.194 50.03)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0h1.443v-8.718c0-1.034.02-1.678.059-1.933.072-.57.239-1.045.5-1.428.262-.383.665-.704 1.208-.963a3.792 3.792 0 011.64-.388c.477 0 .935.102 1.374.305.438.203.805.484 1.099.844.295.36.51.796.648 1.305.098.368.147 1.12.147 2.258V0h1.444v-8.718c0-1.289-.126-2.331-.378-3.126-.252-.795-.756-1.487-1.512-2.076s-1.671-.884-2.744-.884c-1.165 0-2.163.279-2.994.835-.831.556-1.388 1.292-1.669 2.208C.088-11.197 0-10.184 0-8.718z"
                ></motion.path>
              </g>
              <g transform="translate(193.591 36.954)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0h1.669c1.996 0 3.38.124 4.152.373 1.087.353 1.941.989 2.562 1.907.622.919.933 2.042.933 3.369 0 1.394-.337 2.583-1.011 3.571-.674.987-1.613 1.664-2.817 2.03-.904.274-2.392.412-4.467.412H0zm-1.384-1.364v14.44H1.61c2.16 0 3.727-.174 4.702-.52 1.4-.492 2.495-1.342 3.284-2.553.788-1.21 1.183-2.653 1.183-4.329 0-1.446-.313-2.719-.938-3.819C9.216.756 8.405-.056 7.407-.579c-.998-.524-2.397-.785-4.197-.785z"
                ></motion.path>
              </g>
              <motion.path
                fill="#2b2b2a"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M225.859 50.03h1.443V35.59h-1.443z"
              ></motion.path>
              <g transform="translate(256.45 48.99)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0a5.947 5.947 0 01-3.038-.835 6.014 6.014 0 01-2.242-2.25c-.537-.943-.806-1.994-.806-3.153 0-1.716.595-3.165 1.784-4.348 1.19-1.182 2.624-1.772 4.302-1.772 1.121 0 2.158.271 3.112.814a5.834 5.834 0 012.232 2.231c.534.943.802 1.991.802 3.143 0 1.147-.268 2.183-.802 3.109-.534.927-1.286 1.669-2.257 2.226A6.103 6.103 0 010 0m-.063 1.404c2.187 0 4.017-.73 5.49-2.189C6.9-2.244 7.638-4.041 7.638-6.175c0-2.113-.736-3.906-2.206-5.379-1.47-1.472-3.263-2.209-5.378-2.209-2.141 0-3.949.734-5.422 2.2-1.473 1.465-2.21 3.239-2.21 5.32 0 1.388.335 2.673 1.007 3.858A7.32 7.32 0 00-3.826.397 7.382 7.382 0 00-.063 1.404"
                ></motion.path>
              </g>
              <g transform="translate(323.793 47.35)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0l-1.139-.873A6.25 6.25 0 01-3.401.987c-.881.422-1.848.633-2.902.633a6.413 6.413 0 01-3.2-.829c-.981-.554-1.742-1.296-2.282-2.228-.54-.933-.81-1.982-.81-3.147 0-1.761.604-3.229 1.811-4.407 1.208-1.179 2.731-1.768 4.57-1.768 2.023 0 3.714.792 5.075 2.376L0-9.247a7.281 7.281 0 00-2.695-2.125c-1.076-.501-2.279-.751-3.608-.751-2.526 0-4.518.841-5.977 2.522-1.225 1.421-1.836 3.135-1.836 5.145 0 2.113.741 3.892 2.223 5.335 1.482 1.443 3.339 2.165 5.571 2.165 1.348 0 2.565-.268 3.652-.801A7.08 7.08 0 000 0"
                ></motion.path>
              </g>
              <g transform="translate(347.108 48.616)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0v-4.908l2.488-.02c.963 0 1.676.092 2.138.275a2.22 2.22 0 011.082.879c.259.402.389.852.389 1.349 0 .485-.131.925-.394 1.321a2.198 2.198 0 01-1.032.849C4.245-.085 3.537 0 2.547 0zm-1.442 1.414h2.876c1.603 0 2.689-.066 3.258-.197C5.55 1.021 6.247.6 6.783-.046c.537-.645.806-1.439.806-2.384 0-.786-.186-1.477-.555-2.073-.37-.597-.898-1.047-1.586-1.351-.686-.305-1.635-.461-2.846-.467l5.193-6.705H6.01L.816-6.321H0v-6.705h-1.442z"
                ></motion.path>
              </g>
              <g transform="translate(376.696 50.03)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0h8.275v-1.414H1.443v-4.525h6.774v-1.414H1.443v-5.673h6.774v-1.414H0z"
                ></motion.path>
              </g>
              <g transform="translate(412.081 46.962)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0l-2.475-5.227h4.938zm.183 3.068l6.733-14.44H5.357l-2.27 4.751h-6.221l-2.25-4.751h-1.609l6.822 14.44z"
                ></motion.path>
              </g>
              <g transform="translate(437.071 48.616)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0v1.414h7.912V0h-3.22v-13.026H3.22V0z"
                ></motion.path>
              </g>
              <motion.path
                fill="#2b2b2a"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M465.756 50.03h1.443V35.59h-1.443z"
              ></motion.path>
              <g transform="translate(488.306 50.03)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0h1.571l4.785-11.156L11.221 0h1.57L6.509-14.44h-.315z"
                ></motion.path>
              </g>
              <g transform="translate(529.36 48.99)">
                <motion.path
                  fill="#2b2b2a"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M0 0a5.947 5.947 0 01-3.038-.835 6.014 6.014 0 01-2.242-2.25c-.537-.943-.806-1.994-.806-3.153 0-1.716.595-3.165 1.784-4.348 1.19-1.182 2.624-1.772 4.302-1.772 1.121 0 2.158.271 3.112.814a5.84 5.84 0 012.232 2.231c.534.943.802 1.991.802 3.143 0 1.147-.268 2.183-.802 3.109-.534.927-1.286 1.669-2.256 2.226C2.117-.278 1.088 0 0 0m-.063 1.404c2.186 0 4.017-.73 5.49-2.189C6.9-2.244 7.638-4.041 7.638-6.175c0-2.113-.736-3.906-2.206-5.379-1.47-1.472-3.263-2.209-5.378-2.209-2.141 0-3.949.734-5.422 2.2-1.474 1.465-2.21 3.239-2.21 5.32 0 1.388.336 2.673 1.007 3.858A7.32 7.32 0 00-3.826.397 7.382 7.382 0 00-.063 1.404"
                ></motion.path>
              </g>
              {/* END - Estudio Creativo - Subtitle */}
              {/* Start - Border */}
              <g transform="translate(62.285 257.439)">
                <motion.path
                  fill="#1d1d1d"
                  fillRule="nonzero"
                  d="M0 0a5.08 5.08 0 01-5.074-5.075v-179.58A5.08 5.08 0 010-189.729h483.556a5.08 5.08 0 015.073 5.074v179.58A5.08 5.08 0 01483.556 0zm483.556-195.85H0c-6.173 0-11.195 5.022-11.195 11.195v179.58C-11.195 1.098-6.173 6.12 0 6.12h483.556c6.172 0 11.194-5.022 11.194-11.195v-179.58c0-6.173-5.022-11.195-11.194-11.195"
                ></motion.path>
              </g>
              {/* END - Border */}
              <g transform="translate(93.58 121.95)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    duration: 2,
                    ease: "easeInOut",
                    repeatDelay: 2,
                  }}
                  strokeWidth={4}
                  custom={1}
                  d="M0 0c-.866-.866-1.895-1.296-3.091-1.296h-.399c-1.197 0-2.228.399-3.091 1.197-.866.797-1.296 1.925-1.296 3.39v101.305c0 1.327.399 2.424 1.196 3.291.798.862 1.861 1.295 3.191 1.295h.2c1.196 0 2.225-.467 3.091-1.396.863-.931 1.296-1.994 1.296-3.19V2.293h.106A4.28 4.28 0 000 0m1.097-16.452v-2.792c0-1.196-.399-2.228-1.197-3.09-.797-.868-1.795-1.297-2.991-1.297h-.199c-1.331 0-2.428.399-3.291 1.197-.866.797-1.296 1.86-1.296 3.19v1.795c0 1.327.399 2.424 1.196 3.29.798.864 1.861 1.297 3.191 1.297h.2c1.196 0 2.259-.433 3.19-1.297a4.223 4.223 0 001.295-2.293zm4.387 137.201h-26.722V-35.397H5.484c2.792 0 5.016.897 6.681 2.692 1.66 1.795 2.492 4.019 2.492 6.681v9.971c0 2.125-.598 4.019-1.795 5.684a11.897 11.897 0 01-3.038 2.947 13.123 13.123 0 013.038 3.235c1.197 1.794 1.795 3.754 1.795 5.883v109.88c0 2.657-.866 4.851-2.592 6.581-1.73 1.725-3.923 2.592-6.581 2.592"
                ></motion.path>
              </g>
              <g transform="translate(140.543 102.707)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 0.5,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={2}
                  d="M0 0c0-1.33-.434-2.393-1.296-3.19-.867-.798-1.895-1.197-3.091-1.197h-.4c-1.196 0-2.193.429-2.99 1.297-.798.862-1.197 1.894-1.197 3.09v123.84c0 1.327.43 2.424 1.296 3.291.863.863 1.96 1.295 3.291 1.295h.199c1.196 0 2.194-.467 2.991-1.396.798-.931 1.197-1.993 1.197-3.19zm4.387 139.993h-17.748c-2.661 0-4.821-.867-6.481-2.592-1.664-1.73-2.493-3.923-2.493-6.581V-6.979c0-2.527.863-4.687 2.592-6.482 1.726-1.795 3.855-2.692 6.382-2.692H4.387c2.524 0 4.686.897 6.481 2.692 1.795 1.795 2.693 3.955 2.693 6.482V130.82c0 2.658-.867 4.851-2.593 6.581-1.73 1.725-3.923 2.592-6.581 2.592"
                  variants={draw}
                ></motion.path>
              </g>
              <g transform="translate(190.795 242.7)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 1,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0h-17.749c-2.526 0-4.654-.867-6.38-2.592-1.73-1.73-2.594-3.924-2.594-6.581v-137.8c0-2.66.864-4.854 2.594-6.581 1.726-1.729 3.854-2.592 6.38-2.592H9.173v11.766H-8.774c-1.331 0-2.428.399-3.291 1.197-.866.797-1.296 1.86-1.296 3.19v123.84c0 1.196.399 2.259 1.196 3.19.798.929 1.727 1.396 2.792 1.396h.599c1.196 0 2.224-.433 3.091-1.295.862-.867 1.296-1.964 1.296-3.291v-95.123h13.56V-9.173c0 2.524-.897 4.685-2.692 6.48C4.686-.898 2.523 0 0 0"
                ></motion.path>
              </g>
              <g transform="translate(232.274 102.707)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 1.5,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c0-1.196-.398-2.228-1.197-3.09-.797-.868-1.794-1.297-2.99-1.297h-.2c-1.331 0-2.427.399-3.29 1.197-.866.797-1.297 1.86-1.297 3.19v2.394c0 1.062.399 1.993 1.197 2.791.797.798 1.795 1.197 2.991 1.197h.399c1.196 0 2.225-.433 3.091-1.297C-.433 4.219 0 3.123 0 1.795zm4.587 139.993h-17.948c-2.527 0-4.655-.898-6.381-2.693-1.73-1.795-2.593-3.956-2.593-6.48V28.717h13.361v95.123c0 1.327.431 2.424 1.297 3.291.863.863 1.959 1.295 3.29 1.295h.399c1.062 0 1.994-.467 2.791-1.396.799-.931 1.197-1.993 1.197-3.19V22.535c0-1.465-.433-2.593-1.296-3.39-.866-.798-1.895-1.197-3.091-1.197h-8.974c-2.527 0-4.655-.866-6.381-2.593-1.73-1.729-2.593-3.922-2.593-6.581V-6.78c0-2.661.829-4.886 2.493-6.681 1.661-1.795 3.82-2.692 6.481-2.692h26.922V130.82c0 2.524-.867 4.685-2.593 6.48-1.729 1.795-3.857 2.693-6.381 2.693"
                ></motion.path>
              </g>
              <g transform="translate(278.539 102.707)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 2,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c0-1.33-.433-2.393-1.296-3.19-.866-.798-1.895-1.197-3.091-1.197h-.399c-1.196 0-2.193.429-2.991 1.297-.798.862-1.197 1.894-1.197 3.09v123.84c0 1.327.43 2.424 1.297 3.291.862.863 1.959 1.295 3.29 1.295h.2c1.195 0 2.193-.467 2.99-1.396.799-.931 1.197-1.993 1.197-3.19zm4.387 139.993H-22.734V-16.153H4.387c2.525 0 4.687.897 6.481 2.692 1.795 1.795 2.693 3.955 2.693 6.482V130.82c0 2.658-.867 4.851-2.593 6.581-1.729 1.725-3.923 2.592-6.581 2.592"
                ></motion.path>
              </g>
              <g transform="translate(324.404 102.707)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0 }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 2.5,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c0-1.33-.434-2.393-1.296-3.19-.867-.798-1.895-1.197-3.092-1.197h-.398c-1.196 0-2.193.429-2.991 1.297-.798.862-1.197 1.894-1.197 3.09v123.84c0 1.327.43 2.424 1.296 3.291.863.863 1.959 1.295 3.29 1.295h.201c1.196 0 2.193-.467 2.991-1.396.798-.931 1.196-1.993 1.196-3.19zm4.387 139.993h-17.748c-2.661 0-4.821-.867-6.481-2.592-1.664-1.73-2.493-3.923-2.493-6.581V-6.979c0-2.527.863-4.687 2.592-6.482 1.727-1.795 3.855-2.692 6.382-2.692H4.387c2.524 0 4.686.897 6.481 2.692 1.795 1.795 2.693 3.955 2.693 6.482V130.82c0 2.658-.867 4.851-2.593 6.581-1.73 1.725-3.923 2.592-6.581 2.592"
                ></motion.path>
              </g>
              <g transform="translate(370.27 223.954)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0 }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 2.5,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0v-1.196C0-2.393-.434-3.39-1.296-4.187c-.866-.798-1.829-1.197-2.891-1.197h-.4c-1.196 0-2.227.399-3.09 1.197-.867.797-1.297 1.925-1.297 3.39v3.39c0 1.327.399 2.424 1.197 3.291.797.863 1.859 1.295 3.19 1.295h.2c1.197 0 2.225-.467 3.091-1.396C-.434 4.852 0 3.79 0 2.593V.798zm4.388 18.746h-26.723V-137.4h13.361v115.862c0 1.329.43 2.394 1.297 3.192.863.797 1.959 1.196 3.29 1.196h8.775c2.522 0 4.685.898 6.481 2.692 1.795 1.795 2.692 3.955 2.692 6.482V9.573c0 2.657-.866 4.851-2.592 6.581-1.73 1.725-3.924 2.592-6.581 2.592"
                ></motion.path>
              </g>
              <motion.path
                fill="black"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="#1d1d1d"
                initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                transition={{
                  delay: 2,
                  duration: 2,
                  ease: "easeInOut",
                }}
                strokeWidth={4}
                custom={6}
                d="M393.801 230.934h13.36V242.7h-13.36z"
              ></motion.path>
              <motion.path
                fill="black"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="#1d1d1d"
                initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                transition={{
                  delay: 2,
                  duration: 2,
                  ease: "easeInOut",
                }}
                strokeWidth={4}
                custom={6}
                d="M393.801 86.554h13.36v135.805h-13.36z"
              ></motion.path>
              <g transform="translate(439.466 224.752)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 1.5,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c0-1.065-.433-2.063-1.297-2.992-.865-.931-1.893-1.395-3.09-1.395h-.399c-1.33 0-2.362.399-3.091 1.196-.732.797-1.098 1.86-1.098 3.191v17.948h-13.36v-20.54c0-2.527.863-4.656 2.593-6.382a8.88 8.88 0 011.937-1.476 8.286 8.286 0 01-2.038-1.615c-1.663-1.794-2.492-4.022-2.492-6.681v-119.452h13.36v117.657c0 1.197.431 2.225 1.297 3.092.864.863 1.96 1.295 3.291 1.295h.199c1.197 0 2.194-.432 2.992-1.295C-.398-18.316 0-19.344 0-20.541v-117.657h13.561v119.452c0 2.659-.867 4.887-2.593 6.681a8.713 8.713 0 01-2.098 1.613 9.447 9.447 0 011.999 1.478c1.794 1.726 2.692 3.855 2.692 6.382v20.54H0z"
                ></motion.path>
              </g>
              <g transform="translate(485.331 224.552)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    delay: 1,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c0-1.196-.399-2.194-1.196-2.991-.799-.798-1.795-1.196-2.992-1.196h-.2c-1.33 0-2.427.398-3.29 1.196-.867.797-1.297 1.925-1.297 3.389v1.597c0 1.326.399 2.423 1.198 3.29.798.863 1.86 1.296 3.19 1.296h.199c1.197 0 2.225-.467 3.091-1.396C-.433 4.253 0 3.191 0 1.995zm4.387 18.147h-17.748c-2.661 0-4.821-.866-6.482-2.592-1.664-1.729-2.492-3.923-2.492-6.581v-137.799c0-2.661.862-4.855 2.592-6.581 1.725-1.73 3.854-2.593 6.382-2.593H4.387c2.524 0 4.686.897 6.481 2.692 1.795 1.795 2.693 3.955 2.693 6.482v103.1H0v-96.121c0-1.196-.399-2.227-1.196-3.09-.799-.867-1.795-1.297-2.992-1.297h-.2c-1.33 0-2.427.399-3.29 1.197-.867.798-1.297 1.861-1.297 3.19v101.505c0 1.328.43 2.393 1.297 3.191.863.797 1.96 1.196 3.29 1.196h8.775c2.524 0 4.686.898 6.481 2.693s2.693 3.954 2.693 6.481V8.974c0 2.658-.867 4.852-2.593 6.581-1.73 1.726-3.923 2.592-6.581 2.592"
                ></motion.path>
              </g>
              <g transform="translate(526.81 98.32)">
                <motion.path
                  fill="black"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="#1d1d1d"
                  initial={{ pathLength: 0, pathSpacing: 0.2, fillOpacity: 0  }}
                  animate={{ pathLength: 1, pathSpacing: 0.2, strokeWidth:[4,0],  fillOpacity:1 }}
                  transition={{
                    duration: 2,
                    ease: "easeInOut",
                  }}
                  strokeWidth={4}
                  custom={3}
                  d="M0 0c-1.331 0-2.428.399-3.29 1.197-.867.798-1.297 1.861-1.297 3.19V144.38h-13.36V-2.592c0-2.527.862-4.687 2.592-6.482 1.725-1.795 3.854-2.692 6.38-2.692H8.974V0z"
                ></motion.path>
              </g>
            </g>
          </g>
        </g>
      </motion.svg>
    </div>
  );
}

export default LoadingLogo
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../assets/Icon";
import "../assets/styles/Footer.css"
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import '../assets/styles/Footer.css'


const Footer = () => {
  return (
    <>
      <footer class="footer-bar relative bg-bpixel-blue py-10">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap  items-center text-left lg:text-left">
            <div className="logo">
              <Icon color="#FFFFFF" />
            </div>
            <div className="content text-[#FBEA00] px-10 text-center justify-center flex flex-col gap-3">
                <p>Copyright BOCADOPIXEL ESTUDIO CREATIVO</p>
                <p>Todo el material de este sitio es propiedad de BOCADOPICEL ESTUDIO CREATIVO o de nuestros clientes
                  con su consentimiento
                </p>
                <div className='flex justify-center gap-3 text-center'> 
                  <a href="">
                    <p>Políticas de Cookies</p>
                  </a>
                  <a href="">
                    <p>Políticas de Privaciodad</p>
                  </a>
                  <a href="">
                    <p>Aviso Legal</p>
                  </a>
                </div>
            </div>
            <div className="social text-xl text-[#FBEA00]">
              <a href="">
                <InstagramIcon/>
              </a>
              <a href="">
                <InstagramIcon/>
              </a>
              <a href="">
                <YouTubeIcon/>
              </a>
            </div>   
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer
import React, {useState,useEffect} from "react";
import Header from "../components/Header/Header";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import LoadingLogo from "../components/Loaders/LoadingLogo";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import '../assets/styles/FrontPage.css'


const squareVariants = {
  visible: { opacity: 1, scale: 4, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};
function Square() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      className="bg-bpixel-blue h-10 w-10"
    ></motion.div>
  );
}


export default function FrontPage() {

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

  /*useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);*/


  return (
    <>
      <div className="">
        <Header></Header>
        <div className="flex justify-center items-center h-[90vh] w-full bg-bpixel-yellow">
          <LoadingLogo className="block w-full max-w-4xl  mb-20 md:top-0" />
        </div>
        <section id="about-us" className="text-center p-10">
            <h3 className="text-3xl uppercase italic">Quienes Somos?</h3>
            <h5 className="uppercase text-[36px] md:text-[72px] font-bold font-[Lato]"><b className="font-[Smoothy-Regular] text-[#FBEA00] text-[42px] md:text-[82px] italic ">Bocado</b>Pixel - es un estudio creativo especializado en marketing digital, <br className="block xl:hidden"></br>
            <b className="text-bpixel-blue font-[Gellatio-Personal-Use] text-[32px] md:text-[72px] italic break-all"> diseño</b>,
               fotografía de producto & redes sociales
            </h5>
        </section>
        <div className="h-[650px] overflow-hidden">
          <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-02.webp")} alt="" loading="lazy"/>
        </div>
        <section id="services" className="text-center flex-col justify-center p-10">
          <p className="text-[56px] font-[Lato]">"Saborea el éxito en el <b className="font-[KidsWayDemo] italic uppercase">Mundo Digital</b> con <b className="font-[Smoothy-Regular] text-[#FBEA00] text-[42px] md:text-[82px] italic uppercase ">Bocado</b>Pixel."</p>
          <div className="w-full flex justify-center py-5">
            <button className='button-52 text-3xl'>Servicios</button>
          </div>
        </section>
        <div id="video-section" className="h-[650px] overflow-hidden">
        <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-021.webp")} alt="" loading="lazy"/>
        </div>
        <div className="h-[650px] overflow-hidden relative">
          <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-012.webp")} alt="" loading="lazy"/>
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="flex flex-col items-center">
              <h3 className="text-center text-[#FFFFFF] text-[112px] md:text-[164px] leading-[5.5rem] md:leading-[7.5rem]">MAR</h3>
              <h3 className="text-center text-[#FFFFFF] text-[112px] md:text-[164px] leading-[5.5rem] md:leading-[7.5rem]">KET</h3>
              <h3 className="text-center text-[#FFFFFF] text-[112px] md:text-[164px] leading-[5.5rem] md:leading-[7.5rem]">ING</h3>
            </div>
          </div>
        </div>
        <div className="h-[650px] overflow-hidden relative">
          <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-025.webp")} alt="" loading="lazy"/>
          <div className="absolute inset-0 flex justify-center items-center">
            <h3 className="font-[Gellatio-Personal-Use] uppercase text-center text-[#FFFFFF] text-[42px] md:text-[164px] leading-[5.5rem]">Diseño</h3>
          </div>
        </div>
        <div className="h-[650px] overflow-hidden relative">
          <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-05.webp")} alt="" loading="lazy"/>
          <div className="absolute inset-0 flex justify-center items-center">
            <h3 className="text-center text-[#FFFFFF] text-[48px] md:text-[164px] leading-[5.5rem]">ESTRATEGIA</h3>
          </div>
        </div>
        <div className="h-[650px] overflow-hidden relative">
          <img className="burger-img object-cover w-full h-full" src={require("../assets/img/GridGallery/bc-px-06.webp")} alt="" loading="lazy"/>
          <div className="absolute inset-0 flex justify-center items-center">
            <h3 className="text-center font-[Cadogan] text-[#FFFFFF] text-[14px] md:text-[64px] leading-[5.5rem]">FOTOGRAFIA</h3>
          </div>
        </div>
        <section>
          <ContactForm/>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
}

import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer'
import GridGallery from '../components/GridGallery/GridGallery'

const GalleryPage = () => {
  return (<>
    <Header/>
    <section>
        <GridGallery numColumns={3} />
    </section>
    <Footer/>
  </>
  )
}

export default GalleryPage
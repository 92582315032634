import React, { useEffect, useState } from 'react';
import './GridGalleryStyle.scss';


const GridGallery =  ({ numOfColumns })  => {
    const importAllImages = (r) => {
        try {
          let images = {};
          r.keys().forEach((item, index) => {
            images[item.replace('./', '')] = r(item);
          });
          return images;
        } catch (error) {
          console.error('Error importing images:', error);
          return {};
        }
      };
    
      const images = importAllImages(require.context('../../assets/img/GridGallery', false, /\.(png|webp|jpe?g|svg)$/));
       

  return (
    <div className="grid-gallery" style={{ '--num-of-columns': `${numOfColumns}` }}>
        {Object.keys(images).map((imagePath, index) => {
            return(
            <a key={imagePath} className="grid-gallery__item" href="#">
            <img
                className="grid-gallery__image"
                src={require(`../../assets/img/GridGallery/${imagePath}`)}
                alt={`Image ${imagePath}`}
            />
            </a>)
        })}
    </div>
  );
};

export default GridGallery;

import React from 'react'
import { TextField} from '@mui/material';



const ContactForm = () => {

    const isMobile = window.innerWidth < 768; 
  return (
    <div>
        <div className="content flex flex-col gap-5 text-center px-5 md:px-[25%]">
            <h3 className='uppercase text-3xl font-bold'>Contacto de Otro Mundo</h3>
            <p>Queremos saber de ti  y tus ganas de comerte el mundo, envioanos tu pregunta, consulta o duda que tenfas para 
                que nuestro fabuloso equipo se ponga en contacto contigo y demos un bocado juntos
            </p>
        </div>
        <div className='form-container px-2 md:px-10 flex flex-col gap-5'>
        <div className='flex flex-wrap justify-center items-center gap-3 w-full'>
            <TextField
            id="outlined-basic"
            label="Nombre"
            variant="outlined"
            sx={{ m: 1, width: isMobile ? '100%' : '25ch' }} // Set width to 100% on mobile, otherwise 40ch
            />
            <TextField
            id="outlined-basic"
            label="Correo"
            variant="outlined"
            sx={{ m: 1, width: isMobile ? '100%' : '25ch' }} // Set width to 100% on mobile, otherwise 40ch
            />
            <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            sx={{ m: 1, width: isMobile ? '100%' : '25ch' }} // Set width to 100% on mobile, otherwise 40ch
            />
            <TextField
            id="outlined-multiline-static"
            label="Mensaje"
            multiline
            rows={5}
            sx={{ m: 1, width: isMobile ? '100%' : '82ch' }} // Set width to 100% on mobile, otherwise 40ch
            defaultValue="Mensaje"
            />  
        </div>
            <div className="w-full flex justify-center py-5">
          <button className='button-52 text-3xl'>Envío</button>
        </div>
    </div>

    </div>
  )
}

export default ContactForm
import React,{useState} from 'react'
import Icon from '../../assets/Icon';
import "../../assets/styles/NavBar.css"
import Menu from '@mui/icons-material/Menu'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const NavBar = ({menuItems, className}) => {

  const [showMenu, setShowMenu] = useState(false);
  const isMobile = window.innerWidth < 768; 


  return (
    <>
      <div
        className={
          `navbar flex justify-between md:justify-around items-center bg-black` + className
        }
      >
        <div className="logo">
          <a href="/">
          <Icon size={isMobile ? 100:300} color={'#FFFFFF'}/>
          </a>
        </div>
        <div className='block md:hidden px-5'>
        <button onClick={() => setShowMenu(!showMenu)}>
          <Menu/>
        </button>
        </div>
        <div className="hidden md:flex flex-col md:flex-row grow gap-5 justify-center text-center text-white">
          {menuItems.map((menuItem) => {
            return (
              <a href={menuItem.ref} className="uppercase font-Lato text-lg">
                {menuItem.title}
              </a>
            );
          })}
        </div>  
        {showMenu && (
          <div className="md:hidden absolute top-16 right-0 bg-black rounded-lg p-4 navbar transition-opacity duration-300">
            {menuItems.map((menuItem) => {
              return (
                <a href={menuItem.ref} className="block font-[bold] text-[36px] text-white font-Lato  mb-2">
                  {menuItem.title}
                </a>
              );
            })}
            <div className='social-container flex gap-3'>
                    <a href="https://www.instagram.com/bocadopixel/">
                      <InstagramIcon/>
                    </a>
                    <a href="">
                      <YouTubeIcon/>
                    </a>
              </div> 
          </div>
        )}
        <div className='social-container hidden md:flex gap-5'>
              <a href="https://www.instagram.com/bocadopixel/">
                      <InstagramIcon/>
                  </a>
              <a href="">
                <YouTubeIcon/>
              </a>
        </div>      
      </div>
    </>
  );
}

export default NavBar
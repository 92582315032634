import './App.css';
import {Route, Routes } from "react-router-dom";
import FrontPage from './pages/FrontPage';
import GalleryPage from './pages/GalleryPage';

function App() {
  return (
    <Routes>
        <Route path="/"  element={<FrontPage/>} />
        <Route path="/gallery" element={<GalleryPage/>} />
    </Routes>
  );
}

export default App;

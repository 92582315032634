import React from 'react'
import NavBar from './NavBar'


export const menuItems = [
  { title: "Servicios", ref: "#service" },
  { title: "Quienes Somos", ref: "#about-us" },
  { title: "Proyectos", ref: "/gallery" },
  { title: "Contacto", ref: "#contact" },
];

const Header = () => {

  return (
    <>
      <NavBar menuItems={menuItems} className={"w-full p-5"} />
    </>
  );
}

export default Header